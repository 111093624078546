import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import { AlertContentContext } from 'context/Alert';

import { rebuildUpcomingOfferings } from 'shared/apis';

export const DataScreen = () => {
  const { addAlert } = useContext(AlertContentContext);
  const queryClient = useQueryClient();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: rebuildUpcomingOfferings,
    mutationKey: 'rebuildUpcomingOfferings',
    onSuccess: () => {
      queryClient.invalidateQueries('getUpcomingOfferingsLookupTable');
    },
    onError: (error) => {
      addAlert(error);
      console.error('Rebuild Upcoming Offerings error', error);
    },
  });

  return (
    <div className="mt-5 flex justify-center items-center">
      <ul className="flex flex-col space-y-2">
        <li className="flex items-center space-x-2">
          <Button onClick={() => mutate()}>Rebuild Upcoming Offerings</Button>
          {isPending && <Spinner animation="border" />}
          {isError && <FontAwesomeIcon icon={faX} className="text-red-500" />}
        </li>
        <li className="flex items-center space-x-2">
          <Button href="/v1/agenda" target="_blank">
            Agenda
          </Button>
        </li>
      </ul>
    </div>
  );
};
