import { useContext } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { LanguageContext } from 'context/Language';

import { useWindowSize } from 'hooks/useWindowSize';

import ellipse from 'asset/landing/ellipse.svg';
import illustrationLgLtr from 'asset/landing/illustration-lg-ltr.svg';
import illustrationLg from 'asset/landing/illustration-lg.svg';
import pdfDem from 'asset/landing/pdf-demonstration.svg';
import tableDem from 'asset/landing/table-demonstration.svg';
import smartbullLogo from 'asset/logo-online.svg';
import dcmLogo from 'asset/sponsors/dcm.png';
import fnxuLogo from 'asset/sponsors/fnxu.png';
import ibiLogo from 'asset/sponsors/ibi.png';
import leumiLogo from 'asset/sponsors/leumi.png';
import rosarioLogo from 'asset/sponsors/rosario.png';
import valuebaseLogo from 'asset/sponsors/valuebase-logo.png';

import { FeatureList } from './styles';

const CallToAction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex align-items-center flex-col pb-3 md:w-fit gap-4">
      <Button variant="primary" onClick={() => navigate('/authentication')} size="lg">
        {t('screens.landing.actions.join')}
      </Button>
      <span className="small text-secondary">
        {t('screens.landing.text.alreadySignedQuestion')} <Link to="/authentication">{t('screens.landing.text.logInHere')}</Link>
      </span>
    </div>
  );
};

const DemoImagesMobile = () => {
  return (
    <div className="position-relative h-[200px] overflow-hidden mb-3">
      <img src={tableDem} alt="table demonstration" className="w-[200px] position-absolute top-0 end-[-16px]" />
      <img src={pdfDem} alt="pdf demonstration" className="w-[200px] position-absolute bottom-0 start-[-27px]" />
    </div>
  );
};

const LandingScreen = () => {
  const { direction } = useContext(LanguageContext);
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop, isLargeDesktop } = useWindowSize();

  const labels = [
    t('screens.landing.text.simplifyProcess'),
    t('screens.landing.text.enjoyUpdates'),
    t('screens.landing.text.resultAnalysis'),
    t('screens.landing.text.kibulInOnePlace'),
    t('screens.landing.text.experienceConvenience'),
  ];
  return (
    <>
      {/* height is 100vh - 88px */}
      <Container className="d-flex flex-col justify-between pt-3 md:pt-5 md:max-w-[95vw]" style={{ minHeight: 'min(900px, calc(100vh - 88px))' }}>
        <div className="d-flex flex-col gap-4">
          <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] my-4" alt="" />
          <h1 className="text-3xl md:text-5xl xl:text-5xl font-weight-bold"> {t('screens.landing.titles.onlinePublicOfferings')}</h1>
          <FeatureList className="">
            {labels.map((label, index) => (
              <li className="text-md  md:text-xl xl:text-2xl ps-[32px] relative " key={index}>
                {label}
              </li>
            ))}
          </FeatureList>

          {(isDesktop || isLargeDesktop) && <CallToAction />}
        </div>

        {(isMobile || isTablet) && <DemoImagesMobile />}

        <div className="grow"> </div>
        <div className="d-flex flex-col lg:flex-row text:lg md:text-xl align-items-center gap-4 md:gap-2 text-center md:text-start	">
          {t('screens.landing.text.sponsors')}
          <div className="d-flex flex-row gap-6 align-items-center">
            <img className="max-h-[20px] max-w-[160px] md:max-h-[55px]" alt="IBI Logo" src={ibiLogo} />
            <img className="max-h-[20px] max-w-[160px] md:max-h-[34px]" alt="DCM Logo" src={dcmLogo} />
            <img className="max-h-[20px] max-w-[160px] md:max-h-[55px]" alt="Rosario Logo" src={rosarioLogo} />
            <img className="max-h-[20px] max-w-[160px] md:max-h-[55px]" alt="Leumi Logo" src={leumiLogo} />
            <img className="max-h-[20px] max-w-[160px] md:max-h-[55px]" alt="Fnxu Logo" src={fnxuLogo} />
            <img className="max-h-[20px] max-w-[160px] md:max-h-[55px]" alt="Valuebase Logo" src={valuebaseLogo} />
          </div>
          {(isMobile || isTablet) && <CallToAction />}
        </div>
      </Container>

      <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
        <img
          src={ellipse}
          className="position-absolute w-[200px] start-[-90px] bottom-[350px]  md:start-[-140px] md:top-unset md:bottom-[-20px] md:w-[350px]"
          alt="decorative ellipse"
        />

        {isDesktop ? (
          direction === 'rtl' ? (
            <img src={illustrationLg} alt="illustration" className="h-[88vh] top-[-23px] end-[0px] position-absolute min-h-[500px] max-w-[50vw]" />
          ) : (
            <img src={illustrationLgLtr} alt="illustration" className="h-[88vh] top-[-23px] end-[0px] position-absolute min-h-[500px] max-w-[50vw]" />
          )
        ) : (
          <img
            src={ellipse}
            className="position-absolute end-[-140px] top-[-62px] w-[290px] md:end-[-290px]  md:top-[-100px] md:w-[850px]"
            alt="decorative ellipse"
          />
        )}
      </div>
    </>
  );
};

export default LandingScreen;
